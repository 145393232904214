<template>
   <div class="app-container" dark>

      <v-parallax src="@/assets/header_img.webp"></v-parallax>

      <v-card class="pa-5 elevation-1 v-sheet v-sheet--shaped whiteBlock">
         <h1 >Welcome to METABIM</h1>

         <div class="bar3">
            <img src="@/assets/t_sites.webp" @click="goToSites()">
            <img src="@/assets/t_systems.webp" @click="goToSystemss()">
            <img src="@/assets/t_models.webp" @click="goToPointcloud()">
         </div>
         <svgEditor />
      </v-card>
   </div>
</template>

<script>
import svgEditor from '@/components/svgEditor.vue';

export default {
   components: {
      svgEditor,
   },
   data() {
      return {

      };
   },
   mounted() {

   },
   methods: {
      goToSites() { this.$router.push({ path: 'sites' }); },
      goToSystemss() { this.$router.push({ path: 'systems' }); },
      goToPointcloud() { this.$router.push({ path: 'pointcloud' }); },
      goToLogin() {}
   },
};
</script>

<style scoped>
.bar3{
   display: flex;
   justify-content: space-evenly;
   width: 100%;
}
.bar3 img{
   height: 270px;
   cursor: pointer;
}
.bar3 img:hover{
filter: brightness(0.8);
}
.whiteBlock {
   position: relative;
   top:-50px;
}
</style>
